import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaArrowRight } from "react-icons/fa"
import Tracker from "../components/tracker"

const StyledCard = styled.div`
  border-radius: 2rem;
  background-color: ${(props) => props.color};
  @media (max-width: 769px) {
    margin: 0 2rem;
  }
`

const StyledArrow = styled(FaArrowRight)`
  @media (max-width: 769px) {
    width: 100%;
    transform: rotate(90deg);
  }
`

const StyledLink = styled(Link)`
  color: hsl(0, 0%, 21%);
  text-decoration: none;
  :hover {
    color: hsl(204, 86%, 53%);
  }
`

const PathpageTemplate = (props) => {
  return (
    <Layout>
      <SEO title="PathPage" />

      <div className="columns is-centered">
        <div className="column is-4">
          <StyledCard color={props.data.strapiPathpage.Color}>
            <div className="card-content has-text-centered">
              <p className="title">
                {props.data.strapiPathpage.Title}&nbsp;
                {props.data.strapiPathpage.url === "home" ? undefined : (
                  <Tracker id={props.data.strapiPathpage.id} />
                )}
              </p>
              <p className="subtitle">
                {props.data.strapiPathpage.Description}
              </p>
            </div>
          </StyledCard>
        </div>
      </div>
      <div className="columns is-centered is-vcentered is-multiline mt-6">
        {props.data.strapiPathpage.Paths.map((path, key) => (
          <React.Fragment key={`path-${key}`}>
            <div className="column is-2">
              <StyledCard className="card" color={path.Color}>
                <StyledLink to={`/${path.url}`}>
                  <div className="card-content has-text-centered">
                    <b className="is-size-5">{path.Title}</b>
                    <p className="is-size-6">{path.Description}</p>
                  </div>
                </StyledLink>
              </StyledCard>
            </div>
            {key < props.data.strapiPathpage.Paths.length - 1 && (
              <StyledArrow size="3em" />
            )}
          </React.Fragment>
        ))}
      </div>
      <hr className="hr" />
      <div className="columns is-centered is-vcentered is-multiline mt-5">
        {props.data.strapiPathpage.Extras.map((extra, key) => (
          <div className="column is-3" key={`extra-${key}`}>
            <StyledCard className="card">
              <StyledLink to={`/${extra.url}`}>
                {extra.Image !== "circle" && (
                  <div className="card-img">
                    <figure className="image">
                      <img src={extra.Image} alt={extra.Description} />
                    </figure>
                  </div>
                )}
                <div className="card-content has-text-centered">
                  <b className="is-size-5">{extra.Title}</b>
                  <p className="is-size-6">{extra.Description}</p>
                </div>
              </StyledLink>
            </StyledCard>
          </div>
        ))}
      </div>
    </Layout>
  )
}

PathpageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PathpageTemplate

export const query = graphql`
  query PathpageTemplate($id: String!) {
    strapiPathpage(id: { eq: $id }) {
      id
      Title
      Description
      url
      Color
      Paths {
        Color
        Description
        Shape
        Title
        id
        url
      }
      Extras {
        id
        Title
        Description
        Image
        url
      }
    }
  }
`
